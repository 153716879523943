export const tableColumnsTopCreators = [
  {
    Header: "Ticker",
    accessor: "ticker",
  },
  {
    Header: "Tipo",
    accessor: "tipo",
  },
  {
    Header: "Ult. Precio",
    accessor: "price",
  },
  {
    Header: "Cantidad",
    accessor: "cantidad",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Acciones",
    accessor: "acciones",
  }
];
