import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  extendTheme,
  useColorModeValue,
  ChakraProvider,
  Icon,
  Spinner,
  useToast,
  Box,
  FormLabel,
  Flex,
  Text,
  Avatar,
  Badge, // Importa el ícono de CheckCircle
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdQueryStats, Md123 } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import URL from "constants/url";

const theme = extendTheme({
  colors: {
    brand: {
      500: "#4319FF",
      600: "#82FFC9",
    },
  },
});

function ModalSumarActivos({ isOpen, onClose, decodedToken, actualizar, actives }) {
  const [allActives, setAllActives] = useState(null);

  // AUTOCOMPLETE OPTIONS
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [autocompleteIndex, setAutocompleteIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const audioRef = useRef(null);
  const [tickerName, setTickerName] = useState("");
  const [tickerLogo, setTickerLogo] = useState(null)
  const [ticker, setTicker] = useState({
    tickerId: "",
    amount: "",
  });
  const [userActive, setUserActive] = useState(null)

  const handleAutocomplete = (e) => {
    const { value } = e.target;
    setUserActive(null)
    setTickerName(value);
    if (value === "") {
      setAutocompleteOptions([]);
      setTickerLogo(null)
    } else {
      const active = actives.Actives.find(item => item?.short_name?.toLowerCase() === value?.toLowerCase())
      if(!active) setTickerLogo(null)
      const filterActives = allActives.filter((item) =>
        item.ticker.toLowerCase().startsWith(value.toLowerCase())
      );
      setAutocompleteOptions(filterActives);
    }
  };

  const refs = autocompleteOptions.reduce((acc, value, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});

  const handleOption = async (option) => {
    console.log(option);
    setAutocompleteOptions([]);
    setTickerName(option.ticker);
    setTickerLogo(option.logo)
    setTicker({ ...ticker, tickerId: option.id });
    
    const active = actives.Actives.find(item => item.id === option.id)
    if(active){
      setUserActive(active)
    }
  };

  const scrollToElement = () => {
    if (Object.keys(refs).length > 0) {
      const { current } = refs[autocompleteIndex];
      if (current !== null) {
        refs[autocompleteIndex].current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  useEffect(() => {
    scrollToElement();
  }, [autocompleteIndex]);

  const changeAutocompleteSuggestion = (e) => {
    switch (e.keyCode) {
      case 13:
        const active = autocompleteOptions.find(
          (_, index) => index === autocompleteIndex
        );
        handleOption(active);
        setAutocompleteIndex(0);
        setAutocompleteOptions([]);
        break;
      case 38:
        if (autocompleteIndex > 0) {
          setAutocompleteIndex((prev) => prev - 1);
        } else {
          setAutocompleteIndex(autocompleteOptions.length - 1);
        }
        break;
      case 40:
        if (autocompleteIndex < autocompleteOptions.length - 1) {
          setAutocompleteIndex((prev) => prev + 1);
        } else {
          setAutocompleteIndex(0);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      const actives = await axios.get(`${URL}/api/actives`);
      setAllActives(actives.data);
    })();
  }, []);

  const handleClose = () => {
    setTicker({
      tickerId: "",
      amount: "",
    });
    setTickerName(null)
    setTickerLogo(null)
    setUserActive(null)
    setAutocompleteOptions([]);
    onClose();
  };

  const handleTicker = (e) => {
    setTicker({ ...ticker, [e.target.name]: e.target.value });
  };

  const handleAgregarActivo = async () => {
    setIsLoading(true);

    const body = { ...ticker, ...decodedToken };

    await axios.post(`${URL}/api/actives`, body);

    // Simula una operación asincrónica
    setTimeout(() => {
      setIsLoading(false);

      // Reproduce el sonido
      // if (audioRef.current) {
      //   audioRef.current.play();
      // }

      // Muestra el Toast con el mensaje "Activo Agregado"
      toast({
        position: "bottom",
        duration: 3000,
        isClosable: true,
        render: () => (
          <Box
            color="white"
            p={3}
            bg="#38B6FF"
            borderRadius="md"
            fontWeight="500"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            animate={{ opacity: [0, 1], translateY: [10, 0] }}
          >
            Activo Agregado a tu balance!
            <Icon
              as={CheckCircleIcon}
              boxSize={5}
              motion={{ type: "spring", stiffness: 400, damping: 25 }}
            />
          </Box>
        ),
      });
      actualizar();
      handleClose();
    }, 2000); // Ejemplo: espera 2 segundos antes de restablecer el estado
  };

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <ChakraProvider theme={theme}>
      <audio ref={audioRef} src="../sounds/success-sound.mp3" />
      <Modal onClose={handleClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>Agregar Activo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Flex direction="column" position="relative">
                <FormLabel
                  display="flex"
                  //ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={useColorModeValue("navy.700", "white")}
                  //mb="8px"
                >
                  Ticker
                </FormLabel>
                <InputGroup>
                  <InputLeftElement fontSize="30px" display="flex" alignItems="center" justifyContent="center" height="100%">
                    <Avatar
                      src={tickerLogo ? tickerLogo : DEFAULT_ICON}
                      w="30px"
                      h="30px"
                      //me="8px"
                      backgroundColor="white"
                      borderWidth="1px"
                      borderColor="#eaeaea"
                    />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="Criptomoneda, Cedear o Acción"
                    //mb="24px"
                    fontWeight="500"
                    size="lg"
                    mt="0px"
                    onChange={handleAutocomplete}
                    onKeyDown={changeAutocompleteSuggestion}
                    value={tickerName}
                  />
                </InputGroup>

                {autocompleteOptions.length > 0 ? (
                  <Box
                    position="absolute"
                    top="80px"
                    width="100%"
                    backgroundColor="#fff"
                    zIndex="1000"
                    maxHeight="200px"
                    overflowY="scroll"
                    borderTopRadius="6px"
                    borderEndRadius="6px"
                    borderLeftWidth="1px"
                    borderRightWidth="1px"
                    display="flex"
                    flexDirection="column"
                  >
                    {autocompleteOptions.map((item, index) => {
                      return (
                        <Box
                          p="16px"
                          borderTopColor="#e2e8f0"
                          borderTopWidth="1px"
                          width="100%"
                          onClick={() => handleOption(item)}
                          cursor="pointer"
                          backgroundColor={
                            autocompleteIndex === index ? "#eaeaea" : ""
                          }
                        >
                          <Flex align="center" ref={refs[index]}>
                            <Avatar
                              src={item.logo ? item.logo : DEFAULT_ICON}
                              w="40px"
                              h="40px"
                              me="8px"
                              backgroundColor="white"
                              borderWidth="1px"
                              borderColor="#eaeaea"
                            />
                            <Flex justifyContent="space-between" w="100%">
                              <Flex direction="column">
                                <Text
                                  color={textColor}
                                  fontSize="sm"
                                  fontWeight="600"
                                >
                                  {item.ticker}
                                </Text>
                                <Text
                                  color={textColorSecondary}
                                  fontSize="sm"
                                  fontWeight="400"
                                >
                                  {item.nombre}
                                </Text>
                              </Flex>
                              <Badge
                                alignSelf="flex-start"
                                justifySelf="flex-start"
                              >
                                {item.Type.type}
                              </Badge>
                            </Flex>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}
              </Flex>

              <Flex direction="column" position="relative">

              <FormLabel
                  display="flex"
                  //ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={useColorModeValue("navy.700", "white")}
                  //mb="8px"
                >
                  Cantidad
                </FormLabel>
              <InputGroup>
              
                <InputLeftElement fontSize="30px"  height="100%">
                  <Md123 />
                </InputLeftElement>
                <Input
                  name="amount"
                  type="number"
                  placeholder="Cantidad"
                  value={ticker?.amount}
                  onChange={handleTicker}
                  fontSize="sm"
                  fontWeight="500"
                    size="lg"
                    mt="0px"
                  focusBorderColor={useColorModeValue("brand.500", "white")}
                />
              </InputGroup>
              </Flex>
              {userActive ?
            <>                
                <Flex direction="column" gap="6px">         
                  <Text fontSize="sm" fontWeight="500">En tu cartera:</Text>
                  <Flex direction="row" gap="20px" background="#f6f6f6" borderRadius="20px" padding="10px" justifyContent="space-between">
                    <Flex align="center">
                    <Avatar
                        src={userActive.logo ? userActive.logo : DEFAULT_ICON}
                        w="40px"
                        h="40px"
                        me="8px"
                        backgroundColor="white"
                    />
                    <Flex direction="column">
                        <Text color={textColor} fontSize="sm" fontWeight="600">
                        {userActive.ticker}
                        </Text>
                        <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                        {userActive.nombre}
                        </Text>
                    </Flex>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="sm" textAlign="right">Cantidad: {userActive.User_Active.amount}</Text>
                      <Text fontSize="sm">Total:  
                      US$ {userActive.id_tipo === 2
                                ? (userActive.valor /
                                  userActive.ratio) *
                                  userActive.User_Active.amount
                                : userActive.valor *
                                userActive.User_Active.amount}</Text>
                    </Flex>
                  </Flex>
                </Flex>     
            </>
            : null}
            </Stack>


          </ModalBody>
          <ModalFooter>
          <Flex gap="10px" width="100%">
                <Button
                onClick={handleClose}
                variant="outline"
                style={{
                    color: "#4319FF",
                    width: "50%",
                    fontSize: "14px"
                }}
                >
                    Cancelar
                </Button>
                <Button
                onClick={handleAgregarActivo}
                isDisabled={isLoading}
                style={{
                    backgroundColor: "#4319FF",
                    color: "white",
                    width: "50%",
                    fontSize: "14px"
                }}
                >
                {isLoading ? (
                    <Spinner size="sm" color="white" />
                ) : (
                    userActive ? "Actualizar Activo" : "Agregar Activo"
                )}
                </Button>
            </Flex>
            {/* <Button
              onClick={handleAgregarActivo}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#4319FF",
                color: "white",
              }}
            >
              {isLoading ? (
                <Spinner size="sm" color="white" />
              ) : (
                "Agregar Activo a mi Cartera"
              )}
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ModalSumarActivos;
