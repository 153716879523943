// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, { useState } from "react";

export default function Conversion(props) {
  const { porcentMerval, porcentCrypto, pieChartData, porcentAcciones, ...rest } = props;
  const [activos, setActivos] = useState({
    merval: pieChartData[0],
    cryto: pieChartData[1],
  });
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Composición de Cartera
        </Text>
      </Flex>

      {pieChartData[0] !== 0 || pieChartData[1] !== 0 ? (
        <PieChart
          h="100%"
          w="100%"
          chartData={[
            parseFloat(porcentMerval.toFixed(2)),
            parseFloat(porcentCrypto.toFixed(2)),
            parseFloat(porcentAcciones.toFixed(2))
          ]}
          chartOptions={pieChartOptions}
        />
      ) : null}

      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
        justifyContent="center"
      >
        <Flex direction="column" py="5px" textAlign="center">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#6AD2FF" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              Cripto
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {porcentCrypto.toFixed(2)}%
          </Text>
        </Flex>

        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />

        <Flex
          direction="column"
          py="5px"
          textAlign="center"
          alignContent="flex-start"
        >
          <Flex align="center">
            <Box h="8px" w="8px" bg="brand.500" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              Merval
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {porcentMerval.toFixed(2)}%
          </Text>
        </Flex>

        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />

        <Flex
          direction="column"
          py="5px"
          textAlign="center"
          alignContent="flex-start"
        >
          <Flex align="center">
            <Box h="8px" w="8px" bg="#5f48fe" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              Acciones
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {porcentAcciones.toFixed(2)}%
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
