import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import DefaultAuth from "layouts/auth/Default";
import { FaCheck } from "react-icons/fa";
import illustration from "assets/img/auth/auth.png";
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import { HSeparator } from "components/separator/Separator";
import URL from "constants/url";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [loginStatusGoogle, setLoginStatusGoogle] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const history = useHistory();
  const toast = useToast();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const [googleProfile, setGoogleProfile] = useState(null)

  const login = useGoogleLogin({
    onSuccess: codeResponse => setGoogleProfile(codeResponse),
  });

  useEffect(() => {
    const getProfileGoogle = async () => {
      if (googleProfile) {
        const user = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleProfile.access_token}`, {
          headers: {
            Authorization: `Bearer ${googleProfile.access_token}`,
            Accept: "application/json",
          },
        })
        console.log(user)
        const response = await fetch(`${URL}/api/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user.data.email, type: "Google" }),
        });
        const data = await response.json()
                if(response.status === 200){
          setLoginStatusGoogle("success");
          toast({
            title: "Inicio de sesión exitoso",
            description: "¡Bienvenido de nuevo!",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          localStorage.setItem("token", data.token)
          setTimeout(() => {
            // Redireccionar a la página de inicio u otra página después del inicio de sesión
            history.push("/admin");
          }, 2000);
        }
      }
    }
    getProfileGoogle()
  }, [googleProfile]);

  useEffect(() => {
    const verfiyEmail = async () => {
      if(token){
        const response = await fetch(`${URL}/api/auth/verify_email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });
        if(response.status === 200){
          toast({
            title: "¡Email verificado!",
            description: "Ya podés iniciar sesión",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
      }
    }
    verfiyEmail()
  }, [token])

  const handleEmail = (e) => {
    const { value } = e.target
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value));
    setEmail(value);
  }

  const handlePassword = (e) => {
    const { value } = e.target
    setIsPasswordValid(value.length >= 7);
    setPassword(value);
  }

  const handleLogin = async () => {
    try {
      setLoading(true);

      if (!isEmailValid || !isPasswordValid) {
        return;
      }

      const response = await fetch(`${URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json().catch((error) => {
        console.error("Error al procesar la respuesta JSON:", error);
      });

      console.log(data); // Imprime la respuesta completa para debug

      if (response.ok) {
        setLoginStatus("success");
        toast({
          title: "Inicio de sesión exitoso",
          description: "¡Bienvenido de nuevo!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        localStorage.setItem("token", data.token)
        setTimeout(() => {
          // Redireccionar a la página de inicio u otra página después del inicio de sesión
          history.push("/admin");
        }, 2000);
      } else {
        setLoginStatus("error");
        toast({
          title: "Error en el inicio de sesión",
          description: data?.error === "User is not verified" ? "Usuario no verificado." : "Credenciales incorrectas. Inténtalo de nuevo.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setLoginStatus("error");
      toast({
        title: "Error en el inicio de sesión",
        description: "Hubo un problema con el inicio de sesión. Inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => setShow(!show);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={useColorModeValue("navy.700", "white")} fontSize="36px" mb="24px">
            Iniciar Sesión
          </Heading>
        </Box>
        <Button
              role="button"
              type="submit"
              onClick={() => login()}
              fontSize="sm"
              variant="outline"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              backgroundColor={"#fff"}
              color="#202020"
              display="flex"
              gap="10px"
            >
              <Icon fontSize="24px"><FcGoogle /></Icon>
  {loginStatusGoogle === "success" ? (
                "Inicio de sesión exitoso"
              ) : (
                "Iniciar sesión con Google"
              )}
            </Button>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
                  <HSeparator />

          <form>
          <FormControl marginTop="24px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={useColorModeValue("navy.700", "white")}
              mb="8px"
              gap="2px"
            >
              Email
              <Text color={isEmailValid ? "#29dd8f" : "#4319FF"}>*</Text>
            </FormLabel>
            <InputGroup mb="24px">
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="michaelscott@dundermifflin.com"
                //mb="24px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={
                  handleEmail
                }
              />
              {isEmailValid && (
                <InputRightElement height="100%">
                  <Icon as={FaCheck} color="#29dd8f" />
                </InputRightElement>
              )}
            </InputGroup>

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={useColorModeValue("navy.700", "white")}
              display="flex"
              gap="2px"
            >
              Password
              <Text color={isPasswordValid ? "#29dd8f" : "#4319FF"}>*</Text>
            </FormLabel>
            <InputGroup size="md" mb="6px">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 caracteres"
                //mb="6px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={handlePassword}
              />
              
              {isPasswordValid && (
                <InputRightElement height="100%">
                  <Icon as={FaCheck} color="#29dd8f" />
                </InputRightElement>
              )}
              <InputLeftElement height="100%">
                <Icon
                  color={useColorModeValue("gray.500", "white")}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputLeftElement>
              
            </InputGroup>

            <NavLink to="/auth/contraseña">
                <Text color={useColorModeValue("brand.500", "white")} as="span"  fontWeight="500" fontSize="14px" display="flex" mb="24px">
                  Olvidé mi contraseña
                </Text>
              </NavLink>

            <Button
              role="button"
              type="submit"
              onClick={handleLogin}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="10px"
              backgroundColor={loginStatus === "success" ? "#29dd8f" : ""}
              disabled={loading || loginStatus === "success" || !isEmailValid || !isPasswordValid}
            >
              {loading ? (
                <Spinner size="sm" />
              ) : loginStatus === "success" ? (
                "Inicio de sesión exitoso"
              ) : (
                "Iniciar Sesión"
              )}
            </Button>            
          </FormControl>
          </form>
          <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
            <Text color={useColorModeValue("gray.400", "secondaryGray.600")} fontWeight="400" fontSize="14px">
              ¿No estás registrado?
              <NavLink to="/auth/registrarse">
                <Text color={useColorModeValue("brand.500", "white")} as="span" ms="5px" fontWeight="500">
                  ¡Creá tu Cuenta!
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
