import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Usa from "assets/img/dashboards/usa.png";
import { getPieChartData } from "variables/charts";
import Dblue from "assets/img/dashboards/dblue.png";
import Arg from "assets/img/dashboards/arg.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect } from "react";
import { MdCurrencyBitcoin, MdAccountBalance } from "react-icons/md";
import PieCard from "views/admin/default/components/PieCard";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import URL from "constants/url";

const UserReports = () => {
  const [isUsaSelected, setIsUsaSelected] = useState(true);
  const history = useHistory();
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [criptoValue, setCriptoValue] = useState(0);
  const [mervalValue, setMervalValue] = useState(0);
  const [accionesValue, setAccionesValue] = useState(0)
  const [totalInversiones, setTotalInversiones] = useState(
    criptoValue + mervalValue
  );
  const [dolarBlueValue, setDolarBlueValue] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("usa");
  const [porcentMerval, setPorcentMerval] = useState(0);
  const [porcentCrypto, setPorcentCrypto] = useState(0);
  const [porcentAcciones, setPorcentAcciones] = useState(0)
  const [pieChartData, setPieChartData] = useState([]);

  const handleSwitchClick = () => {
    setIsUsaSelected((prevIsUsaSelected) => !prevIsUsaSelected);
    setSelectedCurrency((prevCurrency) =>
      prevCurrency === "arg" ? "usa" : "arg"
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      const decodedToken = jwtDecode(token);
      axios
        .get(`${URL}/api/actives/${decodedToken.userId}`)
        .then((resp) => {
          let sumCripto = 0;
          let sumMerval = 0;
          let sumAcciones = 0

          resp.data.Actives.forEach((elem) => {
            if (elem.id_tipo === 1) {
              let aux = elem.valor * elem.User_Active.amount;
              sumCripto += aux;
            }
            if (elem.id_tipo === 2) {
              let aux =
                elem.id_tipo === 2
                  ? (elem.valor / elem.ratio) * elem.User_Active.amount
                  : elem.valor * elem.User_Active.amount;
              sumMerval += aux;
            }
            if (elem.id_tipo === 3){
              let aux = elem.valor * elem.User_Active.amount;
              sumAcciones += aux;
            }
          });

          // Calcular los porcentajes en función de los valores originales en dólares
          const totalInversiones = sumCripto + sumMerval + sumAcciones;
          const porcentMerval = (sumMerval * 100) / totalInversiones;
          const porcentCrypto = (sumCripto * 100) / totalInversiones;
          const porcentAcciones = (sumAcciones * 100) / totalInversiones;

          // Obtener el valor del dolar blue multiplicado según la moneda seleccionada
          const dolarBlueMultiplier =
            selectedCurrency === "arg" ? dolarBlueValue : 1;

          setCriptoValue(
            selectedCurrency === "arg"
              ? sumCripto * dolarBlueMultiplier
              : sumCripto
          );
          setMervalValue(
            selectedCurrency === "arg"
              ? sumMerval * dolarBlueMultiplier
              : sumMerval
          );
          setAccionesValue(
            selectedCurrency === "arg"
              ? sumAcciones * dolarBlueMultiplier
              : sumAcciones
          );


          // Guardar los valores y porcentajes actualizados
          setTotalInversiones(totalInversiones);
          setPorcentAcciones(porcentAcciones)
          setPorcentMerval(porcentMerval);
          setPorcentCrypto(porcentCrypto);

          // Verificar si los porcentajes son NaN o no están definidos
          isNaN(porcentMerval)
            ? setPorcentMerval(0)
            : setPorcentMerval(porcentMerval);

          isNaN(porcentCrypto)
            ? setPorcentCrypto(0)
            : setPorcentCrypto(porcentCrypto);
          
          isNaN(porcentAcciones)
            ? setPorcentAcciones(0)
            : setPorcentAcciones(porcentAcciones);
        })

        .catch((error) => {
          console.error("Error fetching actives:", error);
        });
      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history, selectedCurrency, dolarBlueValue]);
  
  useEffect(() => {
    axios
      .get("https://api.bluelytics.com.ar/v2/latest")
      .then((response) => {
        const blueValue = response.data.blue.value_sell;
        console.log("Dólar Blue Value:", blueValue);
        setDolarBlueValue(blueValue);
      })
      .catch((error) => {
        console.error("Error fetching blue dollar value:", error);
      });
  }, []);

  useEffect(() => {
    setPieChartData([
      Number(porcentMerval.toFixed(2)),
      Number(porcentCrypto.toFixed(2)),
      Number(porcentAcciones.toFixed(2))
    ]);

  }, [porcentCrypto, porcentMerval, porcentAcciones])

  useEffect(() => {
    // Actualizar totalInversiones cada vez que criptoValue o mervalValue cambien
    setTotalInversiones(criptoValue + mervalValue + accionesValue);
  }, [criptoValue, mervalValue, accionesValue]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}
    mt='30px'>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <Flex
              me="-16px"
              mt="0px"
              onClick={handleSwitchClick}
              alignItems="center"
              transition=" opacity 1s ease 0s"
              cursor="pointer"
            >
              <FormLabel htmlFor="balance">
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Avatar src={isUsaSelected ? Usa : Arg} />}
                  cursor="pointer"
                />
              </FormLabel>
              <IconBox
                id="balance"
                variant="mini"
                mt="5px"
                me="0px"
                defaultValue={isUsaSelected ? "usa" : "arg"}
              />
            </Flex>
          }
          name="Total Inversiones"
          value={`${
            selectedCurrency === "usa" ? "US$" : "$"
          }${totalInversiones.toFixed(2)}`}
          flex="1"
          maxH="100%"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Avatar src={Dblue} />}
            />
          }
          name="valor Dolar Blue"
          value={`$${
            dolarBlueValue !== null ? dolarBlueValue.toFixed(2) : "Cargando..."
          }`}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdCurrencyBitcoin}
                  color={brandColor}
                />
              }
            />
          }
          name="Criptomonedas"
          value={`${
            selectedCurrency === "usa" ? "US$" : "$"
          }${criptoValue.toFixed(2)}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdAccountBalance}
                  color={brandColor}
                />
              }
            />
          }
          name="Merval"
          value={`${
            selectedCurrency === "usa" ? "US$" : "$"
          }${mervalValue.toFixed(2)}`}
        />
        <MiniStatistics
          startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Avatar src={Arg} />}
                />
            // <Text bg={boxBg} w="56px" h="56px" fontSize="32px" borderRadius="100%" display="flex" alignItems="center" justifyContent="center">🇦🇷</Text>
            // <IconBox
            //   w="56px"
            //   h="56px"
            //   bg={boxBg}
            //   icon={
            //     <Icon w="32px" h="32px" as="🇦🇷"> 🇦🇷</Icon>
               
            //     // <Icon
            //     //   w="32px"
            //     //   h="32px"
            //     //   as={MdAccountBalance}
            //     //   color={brandColor}
            //     // />
            //   }
            // />
          }
          name="Acciones"
          value={`${
            selectedCurrency === "usa" ? "US$" : "$"
          }${accionesValue.toFixed(2)}`}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap="20px" mb="20px">
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="0px">
          {porcentCrypto !== Infinity && porcentMerval !== Infinity ? (
            <PieCard
              porcentCrypto={porcentCrypto}
              porcentMerval={porcentMerval}
              porcentAcciones={porcentAcciones}
              pieChartData={pieChartData}
            />
          ) : null}

          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
};

export default UserReports;
