import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import Ticker from 'components/ticker/Ticker';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { GoogleOAuthProvider } from "@react-oauth/google"

ReactDOM.render(
	<GoogleOAuthProvider clientId="514126153032-mbvrkb54cfl7j4r3k3d6nijj8fo30id1.apps.googleusercontent.com">
	<ChakraProvider theme={theme}>
		<Ticker/>
		<React.StrictMode>
				<BrowserRouter>
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/admin`} component={AdminLayout} />
						<Route path={`/rtl`} component={RtlLayout} />
						<Redirect from='/' to='/auth/iniciar-sesion' />
					</Switch>
				</BrowserRouter>
		</React.StrictMode>
	</ChakraProvider>
	</GoogleOAuthProvider>,
	document.getElementById('root')
);
