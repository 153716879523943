/* eslint-disable */
import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdPerson, MdHome, MdLock, MdAddTask } from "react-icons/md";
import MainDashboard from "views/admin/default";
import GestionActivos from "views/admin/gestionactivos";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import SignInCentered from "views/auth/signIn";
import Register from "views/auth/register";
import Contraseña from "views/auth/contraseña";

const routes = [
  {
    name: "Resumen",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    hidden: false,
  },
  {
    name: "Gestión de Activos",
    layout: "/admin",
    path: "/gestion-activos",
    icon: <Icon as={MdAddTask} width="20px" height="20px" color="inherit" />,
    component: GestionActivos,
    secondary: true,
    hidden: false,
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "/perfil",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    secondary: true,
    hidden: false,
  },
  {
    name: "Iniciar Sesión",
    layout: "/auth",
    path: "/iniciar-sesion",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Registrarse",
    layout: "/auth",
    path: "/registrarse",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Register,
    hidden: true,
  },
  {
    name: "Contraseña",
    layout: "/auth",
    path: "/contraseña",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Contraseña,
    hidden: true,
  },
];

export default routes;
