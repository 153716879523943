import React, { useState } from "react";
import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import ModalDonacion from "./ModalDonacion"; // Asegúrate de tener la ruta correcta

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
  const [isDonacionModalOpen, setIsDonacionModalOpen] = useState(false);

  const handleDonacionButtonClick = () => {
    setIsDonacionModalOpen(true);
  };

  const handleCloseDonacionModal = () => {
    setIsDonacionModalOpen(false);
  };

  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      bg={bgColor}
      borderRadius="30px"
      position="relative"
      mb='18%'
      mr='20px'
    >
      <Flex
        border="5px solid"
        borderColor={borderColor}
        bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
        borderRadius="50%"
        w="94px"
        h="94px"
        align="center"
        justify="center"
        mx="auto"
        position="absolute"
        left="50%"
        top="-47px"
        transform="translate(-50%, 0%)"
      >
        <Image src={logoWhite} w="40px" h="40px" />
      </Flex>
      <Flex
        direction="column"
        mb="12px"
        align="center"
        justify="center"
        px="15px"
        pt="55px"
      >
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color="white"
          fontWeight="bold"
          lineHeight="150%"
          textAlign="center"
          px="10px"
          mt="0px"
          mb="6px"
        >
          ¡Apoyanos!
        </Text>
        <Text
          fontSize="14px"
          color={"white"}
          fontWeight="500"
          px="10px"
          mb="6px"

          textAlign="center"
        >
          Tu contribución hace posible el crecimiento y mejora de nuestra
          plataforma.
        </Text>
      </Flex>
      <Button
        onClick={handleDonacionButtonClick}
        bg="whiteAlpha.300"
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={"white"}
        fontWeight="regular"
        fontSize="sm"
        minW="185px"
        mx="auto"
      >
        Colaborá con nosotros
      </Button>

      {/* Modal de donación */}
      <ModalDonacion
        isOpen={isDonacionModalOpen}
        onClose={handleCloseDonacionModal}
      />
    </Flex>
  );
}
