import React, { useState, useEffect } from "react";
import { Box, Grid, Text } from "@chakra-ui/react";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Overview = () => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      const decodedToken = jwtDecode(token);

      setUser(decodedToken);
      if (decodedToken.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mt='30px'>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "100%",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={avatar}
          name={user ? `ID# ${user.userId}` : ""}
          job={
            user ? (
              <Text>
                <Text fontWeight="bold">Email:</Text>{" "}
                {user.userEmail}
              </Text>
            ) : (
              ""
            )
          }
        />
      </Grid>
      <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        {/* Agrega aquí las secciones adicionales del componente */}
      </Grid>
    </Box>
  );
};

export default Overview;
