import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Heading, Icon, IconButton, useMediaQuery } from "@chakra-ui/react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select
} from "@chakra-ui/react";
import ModalSumarActivos from "./ModalSumarActivos";
import ModalQuitarActivos from "./ModalQuitarActivos";
import axios from "axios";
import { MdModeEdit, MdDeblur, MdDelete } from "react-icons/md";
import ModalEditarActivo from "./ModalEditarActivo";
import ModalBorrarActivo from "./ModalBorrarActivo";
import DEFAULT_ICON from "../../../../assets/img/default.png"
import { FaCoins } from "react-icons/fa";
import URL from "constants/url";

function TopCreatorTable(props) {
  const { columnsData, decodedToken } = props;
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const columns = useMemo(() => columnsData, [columnsData]);

  const [tickerSelected, setTickerSelected] = useState(null)
  const [dataUser, setDataUser] = useState(null);
  const [filterIdTipo, setFilterIdTipo] = useState("all");

  const [actualizarEstado, setActualizarEstado] = useState(false);

  const actualizar = () => {
    setActualizarEstado(!actualizarEstado);
  };

  const getUserActives = async () => {
    try {
      const data = await axios.get(
        `${URL}/api/actives/${decodedToken.userId}`
      );
      setDataUser(data.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserActives()
  }, []);

  const handleFilterChange = (e) => {
    setFilterIdTipo(e.target.value);
  };

  const data = useMemo(
    () =>
      dataUser && dataUser.Actives
        ? dataUser.Actives.map((item) => ({
            ...item,
            total: item.valor * parseFloat(item.User_Active.amount),
          }))
        : [],
    [dataUser]
  );

  const filteredData = useMemo(() => {
    if (filterIdTipo === "all") {
      return data;
    }
    return data.filter((item) => item.id_tipo === parseInt(filterIdTipo));
  }, [data, filterIdTipo]);

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageSize: 250 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
  } = tableInstance;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuitarModalOpen, setIsQuitarModalOpen] = useState(false);
  const [isEditarModalOpen, setIsEditarModalOpen] = useState(false);
  const [isBorrarModalOpen, setIsBorrarModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openQuitarModal = () => {
    setIsQuitarModalOpen(true);
  };

  const closeQuitarModal = () => {
    setIsQuitarModalOpen(false);
  };

  const closeEditarModal = () => {
    setIsEditarModalOpen(false);
  };

  const closeBorrarModal = () => {
    setIsBorrarModalOpen(false);
  };

  const handleEditModal = (ticker) => {
    setTickerSelected(ticker)
    setIsEditarModalOpen(true)
  }

  const handleDeleteModal = (ticker) => {
    setTickerSelected(ticker)
    setIsBorrarModalOpen(true)
  }

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <>
      <Flex
        direction={isMobile ? "column" : "row"}
        align={{ sm: "flex-start", lg: "center" }}
        justify={isMobile ? "space-between" : "center"}
        w="100%"
        px="22px"
        pb="20px"
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        mt='30px'
      >
        <Flex
          align="center"
          w={isMobile ? "100%" : "auto"}
          mt={isMobile ? "-55px" : "0"}
        >
          <Text
            color={textColor}
            fontSize="xl"
            fontWeight="600"
            mb={isMobile ? "10px" : "0"}
          >
            Mis activos
          </Text>
          <Flex ml={isMobile ? "auto" : "20px"}>
            <Button variant="action" onClick={openModal}>
              {isMobile ? "➕" : "➕ Agregar Activo"}
            </Button>
            {/* <Button variant="action" onClick={openQuitarModal} ml="10px">
              {isMobile ? "➖" : "➖ Quitar Activo"}
            </Button> */}
          </Flex>
        </Flex>

        {isMobile && (
          <Flex align="center" mt={isMobile ? "10px" : "0"} w="100%">
            <Select
              value={filterIdTipo}
              onChange={handleFilterChange}
              fontSize="sm"
              color="gray.500"
              flex="1"
            >
              <option value="all">Todos</option>
              <option value="1">Criptomonedas</option>
              <option value="2">Merval</option>
              <option value="3">Acciones</option>
            </Select>
          </Flex>
        )}

        {!isMobile && (
          <Flex align="center" ml="auto">
            <Text mr="5" color="gray.500" fontSize="sm">
              Filtrar por tipo:
            </Text>
            <Select
              value={filterIdTipo}
              onChange={handleFilterChange}
              maxWidth="120px"
              fontSize="sm"
              color="gray.500"
            >
              <option value="all">Todos</option>
              <option value="1">Criptomonedas</option>
              <option value="2">Merval</option>
              <option value="3">Acciones</option>
            </Select>
          </Flex>
        )}
      </Flex>

      <Box maxWidth="100%" overflowX="hidden" overflowY={isMobile ? "hidden" : "auto"}
      pb={isMobile ? "20px" : 0}>
        <Table {...getTableProps()} variant="simple" color="gray.500">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor="transparent"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Ticker") {
                        data = (
                          <Flex align="center">
                            <Avatar
                              src={
                                cell.row.original.logo
                                  ? cell.row.original.logo
                                  : DEFAULT_ICON
                              }
                              w="40px"
                              h="40px"
                              me="8px"
                              backgroundColor="white"
                              borderWidth="1px"
                              borderColor="#eaeaea"
                            />
                            <Flex direction="column">
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="600"
                              >
                                {cell.row.original.ticker}
                              </Text>
                              <Text
                                color={textColorSecondary}
                                fontSize="sm"
                                fontWeight="400"
                              >
                                {cell.row.original.nombre}
                              </Text>
                            </Flex>
                          </Flex>
                        );

                      } else if (cell.column.Header === "Tipo") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.row.original.Type.type}
                          </Text>
                        );
                      } else if (cell.column.Header === "Ult. Precio") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.column.Header === "Ult. Precio" &&
                            cell.row.original.id_tipo === 2
                              ? `US$ ${(
                                  cell.row.original.valor /
                                  cell.row.original.ratio
                                ).toFixed(2)}`
                              : `US$ ${cell.row.original.valor.toFixed(2)}`}
                          </Text>
                        );
                      } else if (cell.column.Header === "Cantidad") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.row.original.User_Active.amount}
                          </Text>
                        );
                      } else if (cell.column.Header === "Total") {
                        const totalValue =
                          cell.row.original.id_tipo === 2
                            ? (cell.row.original.valor /
                                cell.row.original.ratio) *
                              cell.row.original.User_Active.amount
                            : cell.row.original.valor *
                              cell.row.original.User_Active.amount;

                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.column.Header === "Total"
                              ? `US$ ${totalValue.toFixed(2)}`
                              : ""}
                          </Text>
                        );
                      }
                      else if (cell.column.Header === "Acciones") {
                        data = (
                          <Flex gap="10px">
                            <IconButton size="md" color={textColorSecondary} onClick={() => handleEditModal(cell.row.original)}>
                              <MdModeEdit />
                            </IconButton>
                            <IconButton size="md" color={textColorSecondary} onClick={() => handleDeleteModal(cell.row.original)}>
                              <MdDelete />
                            </IconButton>
                          </Flex>
                        );
                      }
                      return cell.row.original.User_Active.amount !== 0 ? (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "80px", md: "120px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      ) : null;
                    })}
                  </Tr>

                  {/* Información de Cantidad y Total (Nueva Fila) */}
                  {isMobile && (
                    <Tr>
                      <Td
                        colSpan={row.cells.length}
                        fontSize="sm"
                        textAlign="flex-end"
                      >
                        <Text fontWeight="600" mt="2" fontSize="xl">
                          Total: US$ {row.original.total.toFixed(2)}
                        </Text>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              );
            })}

          </Tbody>
        </Table>
        {page.length === 0 ? 
              <Flex width="100%" justifyContent="center" direction="column" alignItems="center" gap="16px" padding="40px">
                <Icon fontSize="2xl" color="#4319ff">
                  <FaCoins />
                </Icon>
                <Flex direction="column" justifyContent="center" alignItems="center" gap="2px">
                  <Heading size="md">No se encontraron activos</Heading>
                  <Text>No encontramos resultados para mostrarte en tu lista de activos</Text>
                </Flex>
                <Button
                style={{
                    backgroundColor: "#4319FF",
                    color: "white",
                    width: "fit-content"
                }}
                onClick={openModal}
                >
                  Agregar activo
                </Button>
              </Flex>
            : null}
      </Box>

      {/* Modal de sumar activos */}
      <ModalSumarActivos
        isOpen={isModalOpen}
        onClose={closeModal}
        decodedToken={decodedToken}
        actualizar={getUserActives}
        actives={dataUser}
      />

      {/* Modal de quitar activos */}
      <ModalQuitarActivos
        isOpen={isQuitarModalOpen}
        onClose={closeQuitarModal}
        decodedToken={decodedToken}
        actualizar={actualizar}
      />

      {isEditarModalOpen ?
      <ModalEditarActivo 
        isOpen={isEditarModalOpen}
        onClose={closeEditarModal}
        ticker={tickerSelected}
        refreshData={getUserActives}
      />
      : null}

      {isBorrarModalOpen ?
      <ModalBorrarActivo 
        isOpen={isBorrarModalOpen}
        onClose={closeBorrarModal}
        ticker={tickerSelected}
        refreshData={getUserActives}
      />
      : null}
    </>
  );
}

export default TopCreatorTable;
