// TickerComponent.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import Ticker from "react-ticker";
import { Avatar, Box, Text } from "@chakra-ui/react";
import icons from "./iconsReference"; // Asegúrate de ajustar la ruta según tu estructura de archivos
import DefaultIcon from "../../assets/img/default.png"
import URL from "constants/url";
const TickerComponent = () => {
  const [allAssets, setAllAssets] = useState([]);
  const [focused, setFocused] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/api/actives`);
        console.log(response.data)
        setAllAssets(response.data);
        console.log("Activos obtenidos:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  if (!allAssets || allAssets.length === 0) {
    return (
      <div style={{ marginBottom: "20px" }}>No hay activos para mostrar.</div>
    );
  }

  return (
    <Box
    
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      style={{
        background:" linear-gradient(180deg, rgba(66,42,251,1) 75%, rgba(131,255,201,0.5887605042016807) 100%)",
        padding: "10px",
        borderRadius: "0px",
        fontFamily: "DM Sans, sans-serif",
        fontSize: "14px",
      }}
    >
      <Ticker speed={5} direction="toLeft" move={focused}>
        {({ index }) => {
          const currentAsset = allAssets[index % allAssets.length];

          /*
          const matchingIcon = icons.find(
            (icon) => icon.ticker === currentAsset.Ticker
          );
          */

          const avatar = (
            <Avatar
              backgroundColor="white"
              src={
                currentAsset.logo
                  ? currentAsset.logo
                  : DefaultIcon
              }
              alt={currentAsset.ticker}
              w="20px"
              h="20px"
              borderRadius="50%"
              marginRight="8px"
            />
          );

          return (
            <Box
              display="flex"
              alignItems="center"
              padding="0px"
              borderRadius="15px"
              fontFamily="DM Sans, sans-serif"
              fontSize="12px"
              marginBottom="0px"
              marginTop="0px"
            >
              {avatar}
              <Text color="#FFFFFF" fontWeight="bold">
                {currentAsset.ticker}
              </Text>
              <span style={{ color: "#FFFFFF", marginLeft: "8px" }}>
                {`US$ ${
                  currentAsset.id_tipo === 2
                    ? (currentAsset.valor / currentAsset.ratio)
                    : currentAsset.valor
                }`}
              </span>
              {"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0"}
            </Box>
          );
        }}
      </Ticker>
    </Box>
  );
};

export default TickerComponent;
