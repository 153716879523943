import React, { useState, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import axios from "axios";
import URL from "constants/url";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const history = useHistory();
  const toast = useToast();
  const audioRef = useRef();

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  const validatePassword = () => {
    setIsPasswordValid(password.length >= 7);
  };

  const handleRegister = async () => {
    try {
      setLoading(true);

      if (!isEmailValid || !isPasswordValid) {
        return;
      }

      const response = await axios.post(
        `${URL}/api/users/register`,
        { email, password }
      );

      if (response.data.success) {
        setRegistrationStatus("success");
        toast({
          title: "¡Registrado correctamente!",
          description: "Te mandamos un email para verificar tu cuenta",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        audioRef.current.play();
        setTimeout(() => {
          history.push("/auth/iniciar-sesion");
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setRegistrationStatus("error");
      if (error.response.status === 409) {
        toast({
          title: "Error",
          description:
            "Este correo electrónico ya está registrado. Inicia sesión.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Error desconocido durante el registro:", error.message);
        toast({
          title: "Error",
          description: "Hubo un problema con tu registro. Inténtalo de nuevo.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => setShow(!show);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={useColorModeValue("navy.700", "white")}
            fontSize="36px"
            mb="24px"
          >
            Registrarse
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={useColorModeValue("navy.700", "white")}
              mb="8px"
            >
              Email<Text color={isEmailValid ? "#82FFC9" : "#4319FF"}>*</Text>
            </FormLabel>
            <InputGroup>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="Michaelscott@dundermifflin.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail();
                }}
              />
              {isEmailValid && (
                <InputRightElement>
                  <Icon as={FaCheck} color="#82FFC9" />
                </InputRightElement>
              )}
            </InputGroup>

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={useColorModeValue("navy.700", "white")}
              display="flex"
            >
              Password
              <Text color={isPasswordValid ? "#82FFC9" : "#4319FF"}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 caracteres"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword();
                }}
              />

              {isPasswordValid && (
                <InputRightElement>
                  <Icon as={FaCheck} color="#82FFC9" />
                </InputRightElement>
              )}
              <InputLeftElement>
                <Icon
                  color={useColorModeValue("gray.500", "white")}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputLeftElement>
            </InputGroup>
            <Button
              role="button"
              type="submit"
              onClick={handleRegister}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              backgroundColor={
                registrationStatus === "success" ? "#82FFC9" : ""
              }
              disabled={
                loading ||
                registrationStatus === "success" ||
                !isEmailValid ||
                !isPasswordValid
              }
            >
              {loading ? (
                <Spinner size="sm" />
              ) : registrationStatus === "success" ? (
                "Registrado correctamente"
              ) : (
                "Registrarse"
              )}
            </Button>
          </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text
              color={useColorModeValue("gray.400", "secondaryGray.600")}
              fontWeight="400"
              fontSize="14px"
            >
              ¿Ya estás registrado?
              <NavLink to="/auth/iniciar-sesion">
                <Text
                  color={useColorModeValue("brand.500", "white")}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Iniciá Sesión
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <audio ref={audioRef} src="../sounds/success-sound.mp3" />
    </DefaultAuth>
  );
}

export default Register;
