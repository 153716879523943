import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Flex,
  Image,
  Tooltip,
  useToast,
  useMediaQuery,
  Box,
  Icon,
  SimpleGrid,
} from "@chakra-ui/react";
import copy from "clipboard-copy";
import QRCode from "qrcode.react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { HSeparator } from "components/separator/Separator";

import bscLogo from "../../../assets/img/BSC_Logo.png";
import ethLogo from "../../../assets/img/ETH_Logo.png";
import { RiFileExcelLine } from "react-icons/ri";

const networks = {
  bsc: {
    short_name: "BSC",
    name: "Binance Smart Chain",
    logo: bscLogo,
    address: "0x8e5E7E4A6898a12131dc8946Fa70eFc65d8D0440",
  },
  eth: {
    short_name: "ETH",
    name: "Ethereum",
    logo: ethLogo,
    address: "0x342E29CFB0b3FAB8784de7168867Bc31fB1A8eFc",
  },
};

function ModalDonacion({ isOpen, onClose }) {
  const [copied, setCopied] = useState({ bsc: false, eth: false });
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const bgColor = "#fff"; // Color en modo claro
  const textColor = "#000"; // Color del texto en modo claro

  const handleCopy = (address, network) => {
    copy(address);
    setCopied((prev) => ({ ...prev, [network]: true }));

    // Muestra la notificación de "Copiado" con una animación y tilde de éxito
    toast({
      position: "bottom",
      duration: 2000,
      isClosable: true,
      render: () => (
        <Box
          color="white"
          p={3}
          bg="#38B6FF"
          borderRadius="md"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          fontWeight="500"
          animate={{ opacity: [0, 1], translateY: [10, 0] }}
        >
          Dirección Copiada!
          <Icon
            as={CheckCircleIcon}
            boxSize={5}
            motion={{ type: "spring", stiffness: 400, damping: 25 }}
          />
        </Box>
      ),
    });

    // Restablece el estado después de un tiempo (para permitir que el usuario vea el mensaje)
    setTimeout(() => {
      setCopied((prev) => ({ ...prev, [network]: false }));
      //setSelectedNetwork(null);
      //onClose();
    }, 2000); // Ejemplo: espera 2 segundos antes de restablecer el estado
  };

  const handleImageClick = (network) => {
    console.log(network);
    setSelectedNetwork(network);
  };

  return (
    <Modal
      onClose={() => {
        setSelectedNetwork(null);
        onClose();
      }}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="20px"
        maxWidth="600px"
        p={4}
        bg={bgColor}
        color={textColor}
        maxH={selectedNetwork ? "80vh" : "auto"}
      >
        <ModalHeader textAlign="left">
          <Text fontWeight="bold" fontSize="xl">
            Gracias por tu apoyo
          </Text>
          <Text fontSize="md" fontWeight="400">
            Tu apoyo es muy importante para nosotros
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack display="flex" direction="column" gap="20px">
            <Flex direction="column" gap="6px">
            <Text fontSize="md" fontWeight="500">
              Seleccioná tu red:
            </Text>
            <SimpleGrid columns={2} gap="10px">
              {Object.keys(networks).map((key) => (
                <Flex
                  key={key}
                  align="center"
                  justify="center"
                  direction="column"
                  onClick={() => handleImageClick(networks[key])}
                  cursor="pointer"
                  borderWidth={selectedNetwork?.short_name === networks[key].short_name ? "2px" : "0"}
                  borderColor={
                    selectedNetwork?.short_name === networks[key].short_name ? "#4319FF" : "transparent"
                  }
                  borderRadius="20px"
                  transition="border-color 0.3s ease-in-out"
                >
                  <Flex
                    direction="row"
                    gap="20px"
                    background="#f6f6f6"
                    borderRadius="20px"
                    padding="10px"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Flex align="center">
                      <Image
                        src={networks[key].logo}
                        alt={`${networks[key].name} Logo`}
                        w={isMobile ? 8 : 10}
                        h={isMobile ? 8 : 10}
                        mx="auto"
                        maxW={isMobile ? 8 : 10}
                        maxH={isMobile ? 8 : 10}
                        borderWidth={selectedNetwork === key ? "2px" : "0"}
                        borderRadius="full"
                        borderColor="#4319FF"
                        me="8px"
                        transition="border-color 0.3s ease-in-out"
                      />
                      <Flex direction="column">
                        <Text color={textColor} fontSize="sm" fontWeight="600">
                          {networks[key].short_name}
                        </Text>
                        <Text fontSize="sm" fontWeight="400">
                          {networks[key].name}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </SimpleGrid>
            </Flex>
            {selectedNetwork ? <HSeparator /> : null}
            {!isMobile && selectedNetwork && (
              <Flex align="center" justify="center" direction="column">
                <Text fontSize="md" mb={2} fontWeight="bold" color="#4319FF">
                  Código QR:
                </Text>
                <QRCode
                  value={selectedNetwork.address}
                  size={128}
                  bgColor={bgColor}
                  fgColor={textColor}
                />
              </Flex>
            )} 
            {selectedNetwork && (
              <Flex align="center" direction="row" mt={4} justifyContent="space-between">
                <Flex direction="column">
                <Text fontSize="md" fontWeight="bold">
                  {selectedNetwork.name}
                </Text>
                <Tooltip label={selectedNetwork.address} placement="top">
                  <Text fontSize="sm" color="#777777">
                    {selectedNetwork.address}
                  </Text>
                </Tooltip>
                </Flex>
                <Button
                  onClick={() =>
                    handleCopy(selectedNetwork.address, selectedNetwork)
                  }
                  variant="outline"
                  mt={2}
                  backgroundColor="#4319FF"
                  textColor="#fff"
                  _hover={{ opacity: '0.8' }}
                >
                  {copied[selectedNetwork] ? "Copiado" : "Copiar"}
                </Button>
              </Flex>
            )}
            {/* {selectedNetwork && (
              <Flex
                fontSize="sm"
                textAlign="center"
                mt={4}
                color="#777777"
                fontStyle="italic"
              >
                ¡Gracias por tu donación! Tu apoyo es muy importante para
                nosotros.
              </Flex>
            )} */}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalDonacion;
